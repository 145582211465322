* {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

/* custom-cursor.css */
html {
  cursor: url("Assets/icons/cursor.svg"), auto;
}

html a,
html a svg,
html a span,
html button,
html button svg,
html [role="button"] {
  cursor: url("Assets/icons/cursor.svg"), pointer !important;
}

html:focus,
html:focus a {
  /* Text cursor/*/
  cursor: url("Assets/icons/cursor.svg"), text;
}

/* styles.css 
html {
  /* Default cursor
  cursor: url("Assets/icons/default.svg"), auto;
}

html:hover {
  /* Pointer cursor
  cursor: url("Assets/icons/pointer.svg"), pointer;
}

html:active {
  /* Wait cursor
  cursor: url("Assets/icons/wait.svg"), wait;
}

html:focus {
  /* Text cursor
  cursor: url("Assets/icons/text.svg"), text;
}

html:not(:active):hover {
  /* Move cursor
  cursor: url("Assets/icons/move.svg"), move;
}

html[disabled] {
  /* Not-allowed cursor for disabled elements
  cursor: url("Assets/icons/not-allowed.svg"), not-allowed;
}

/* Add more cursor styles as needed */

.owl-carousel .owl-stage {
  display: flex;
  align-items: stretch;
}

/* .article-items {
  display: flex;
  flex: 1 0 auto;
  height: 100%;
}

.aticle-box {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
} */
